<template>
  <div>
    <ex4-headline />
   
    <transition name="contenttrans" mode="out-in" appear>
      <section>
        <b-tabs
          type="is-boxed"
          @input="changeTab"
          v-model="currentTab"
          destroy-on-hide
        >
          <template v-for="tab in tabs" >
            <b-tab-item :key="tab.key" :value="tab.key" :label="tab.name">
            </b-tab-item>
          </template>
        </b-tabs>
        <router-view />
      </section>
    </transition>
  </div>
</template>

<script>
import Ex4Headline from "../_helpers/headline.vue"


const DataCountries = (resolve) => {
  require.ensure(
    ["./countries.vue"],
    () => {
      resolve(require("./countries.vue"))
    },
    "data"
  )
}

const DataRegions = (resolve) => {
  require.ensure(
    ["./regions.vue"],
    () => {
      resolve(require("./regions.vue"))
    },
    "data"
  )
}

const DataProducers = (resolve) => {
  require.ensure(
    ["./producers.vue"],
    () => {
      resolve(require("./producers.vue"))
    },
    "data"
  )
}

const DataWines = (resolve) => {
  require.ensure(
    ["./wines.vue"],
    () => {
      resolve(require("./wines.vue"))
    },
    "data"
  )
}

const DataArticles = (resolve) => {
  require.ensure(
    ["./articles.vue"],
    () => {
      resolve(require("./articles.vue"))
    },
    "data"
  )
}

export const dataRoutes = [
  {
    path: "",
    redirect: "articles"
  },
  {
    path: "countries",
    name: "dataCountries",
    component: DataCountries,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "regions",
    name: "dataRegions",
    component: DataRegions,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "producers",
    name: "dataProducers",
    component: DataProducers,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "wines",
    name: "dataWines",
    component: DataWines,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "articles",
    name: "dataArticles",
    component: DataArticles,
    meta: {
      requiresAuth: true
    }
  }
]

export default {
  name: "Data",
  components: {
    DataCountries,
    DataRegions,
    DataProducers,
    DataWines,
    DataArticles,
    Ex4Headline
  },
  data() {
    return {
      searchphrase: "",
      currentTab: "articles",
      tabs: [
        { key: "countries", name: "Länder" },
        { key: "regions", name: "Regionen" },
        { key: "producers", name: "Produzenten" },
        { key: "wines", name: "Weine" },
        { key: "articles", name: "Artikel" }
      ]
    }
  },

  created: function () {
    document.querySelector('title').innerHTML = 'Weinstammdaten | SG Fine Wine'
    this.$store.commit("setHeadline", {
      headline: "Weinstammdaten",
      subheadline: "",
      search: false,
      add: false,
      submenu: [],
      icon: "list_alt"
    })

    var currentRoute = this.$router.currentRoute.fullPath.split("/")
    if (currentRoute[2].length > 2) {
      for(var i = 0; i <= this.tabs.length; i++)
      {
        if(this.tabs[i].key == currentRoute[2])
        {
          this.currentTab = currentRoute[2];
          break;
        }   
      }
    }
  },
  methods: {
    changeTab(e) {
      
      this.$router
        .push({
          path: "/data/" + e
        })
        .catch((err) => {console.log('fehler')})
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
