<template>
  <div>
    <ex4-headline @submenuclicked="subMenuHandler" />
    <transition name="contenttrans" mode="out-in" appear>
      <router-view />
    </transition>
    <div > <a href="#" @click.prevent="convert()">Konvertiere </a></div>
  </div>
</template>

<script>
import Ex4Headline from "../_helpers/headline.vue"

const StockList = (resolve) => {
  require.ensure(
    ["./list.vue"],
    () => {
      resolve(require("./list.vue"))
    },
    "stocks"
  )
}

const StockView = (resolve) => {
  require.ensure(
    ["./view.vue"],
    () => {
      resolve(require("./view.vue"))
    },
    "stocks"
  )
}

export const stockRoutes = [
  {
    path: "",
    redirect: "index"
  },
  {
    path: "index",
    name: "stockList",
    component: StockList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "view/:id",
    name: "stockView",
    component: StockView,
    meta: {
      requiresAuth: true
    }
  }
]

export default {
  name: "Stocks",
  components: {
    StockList,
    StockView,
    Ex4Headline
  },
  data() {
    return {
      searchphrase: ""
    }
  },
// case when (`bottles` <= 0 OR `sold` = 1) then 'nicht mehr verfügbar' when `bottles` < `original_bottles` then 'teilsweise verfügbar' when `bottles` = `original_bottles` then 'vollständig verfügbar' else 'unbekannt' end
  created: function () {
    this.$store.commit("setHeadline", {
      headline: "Lagerbestände",
      search: "search",
      submenu: [
        { name: "Excel-Export (Inventur)", link: "excel" },
        { name: "Excel-Export (Kunden)", link: "excelextern" }
      ],
      icon: "package-variant-closed",
      add:false
    })
  },
  methods: {
    search: function () {
      var t = this
      if (t.$router.currentRoute.fullPath != "/stocks/index") {
        t.$router.push("/stocks/index")
      } else {
        t.$children[1].search()
      }
    },
    subMenuHandler(link) {
      if (link == "excel") {
        this.getExcelExport()
      } else if (link == "excelextern") {
        this.getExcelExternExport()
      }
    },
    getExcelExport() {
      var url =
        this.$store.getters.config.page.apiBaseUrl + "/stocks/getexcelfile/"
      this.$http
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/xlsx" })
          const link = document.createElement("a")
          link.href = URL.createObjectURL(blob)
          link.download = "lager_inventur" + ".xlsx"
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(console.error)
    },
    getExcelExternExport() {
      var url =
        this.$store.getters.config.page.apiBaseUrl +
        "/stocks/getexportexcelfile/"
      this.$http
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/xlsx" })
          const link = document.createElement("a")
          link.href = URL.createObjectURL(blob)
          link.download = "aktueller_bestand" + ".xlsx"
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(console.error)
    },
    convert () {
      this.$http
        .get('/stocks/convert')
        .then((response) => {
          console.log('done');
        })
        .catch(console.error)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
